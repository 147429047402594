import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import { Footer } from './Footer';
import CookieConsent from 'react-cookie-consent';
import Cookies from 'js-cookie';
import { config } from '../config';
import TagManager from 'react-gtm-module';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './Layout.css';

const tagManagerArgs = {
    gtmId: 'GTM-MLLMC7LR'
}

console.log('Environment=' + config.ENVIRONMENT);
if (config.ENVIRONMENT === 'Production') {
    //only initialize google analytics in production environment
    TagManager.initialize(tagManagerArgs)
    console.log('TagManager Loaded');
}

export class Layout extends Component {
    //static displayName = Layout.name;

    render() {
        return (
            <div className="layout">
                <NavMenu />
                <Container className="mobile-container">
                    {this.props.children}
                </Container>
                <Footer />
                <CookieConsent
                    cookieName="BCTransitCookieConsent"
                    enableDeclineButton={true}
                    flipButtons={true}
                    location="bottom"
                    disableButtonStyles={true}
                    style={{ textAlign: "left", background: "#001a31" }}
                    buttonClasses="btn btn-primary"
                    declineButtonClasses="btn btn-secondary"
                    buttonWrapperClasses="btn-wrapper"
                    buttonStyle={{ background: "#1d4a88", color: "#fff", margin:"5px" }}
                    expires={30}
                    onDecline={() => {
                        Cookies.remove("BCTransitCurrentSystem");
                    }}
                >This site uses cookies to provide you with a better browsing experience. To learn more about the different cookies we're using, please see our <a href="/CookiePolicy">cookie policy</a>.</CookieConsent>
            </div>
        );
    }
}

import React, { Component } from 'react';
import './CookiePolicy.css';

export class CookiePolicy extends Component {
    //static displayName = CookiePolicy.name;

    constructor(props) {
        super(props);
        this.state = {  loading: true, error: false };
    }

    componentDidMount() {
        this.getCookiePolicy();    
    }

    render() {
        if (!this.state.error) {
            if (!this.state.loading) {
                return (
                    <div className="container-flex">
                        <div id="homeRoot" className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                            <h1 className="home-page-title">Alerts and Advisories</h1>
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <h1 className="h2">Cookie Policy - Scope and Purpose</h1>
                                <div>
                                    <p>This Cookie Policy explains how BC Transit uses cookies to store and retrieve information on your internet browser and the types of cookies used on this website alerts.bctransit.com (the "Site"). It also explains your options with respect to these cookies.</p>
                                    <p>To the extent that any information collected via cookies on our Site becomes personally identifiable, we will treat it as personal information in accordance with the BC Transit <a href="https://www.bctransit.com/privacy">Privacy Policy</a>.</p>
                                </div>
                                <h1 className="h2">Overview</h1>
                                <h3>What is a cookie?</h3>
                                <p>A "cookie" is a small text or image file that is passed from a website to your computer and is automatically stored by your internet browser on your local hard drive (e.g. Safari, Google Chrome etc.) when you visit a website. Cookie files store information about your visit to the website, such as the time and date and pages viewed, along with other information such as your preferences or your device identification information.</p>
                                <p>Cookies serve a very useful purpose in allowing Site users to customize their preferences on our Site without the need to re-enter the same information on each subsequent visit. Cookies also allow us to better understand how our users access and navigate our Site so that we can design and build a better experience for you.</p>
                                <p>Cookies can be classified as either "First Party Cookies" or "Third Party Cookies". First Party Cookies are placed on your internet browser by BC Transit. Third Party Cookies are placed on your device by another party when you visit our Site so that they can recognize your device when it visits the Site, as well as when it visits other websites. For example, BC Transit uses Google Analytics to better understand how our website is being used and a Google Analytics' cookie would be placed on your internet browser.  Google Analytics provides reports to BC Transit with aggregated information about user demographics and activities by Site visitors. BC Transit is not able to access Third Party Cookies, and third parties are unable to access data in the First Party Cookies we use on our website.</p>
                                <h1 className="h2">Policy</h1>
                                <p>At BC Transit, we respect your right to privacy. You can disable cookies by adjusting the settings on your browser. If you disable or refuse cookies, please note that some parts of the Site may not function properly. You may also delete any First Party cookies that are already on your computer, however, please note that your experience on our Site may be impacted. You can find more information about cookies, how to control your cookie preferences and how to delete cookies at <a href="https://www.aboutcookies.org/">www.aboutcookies.org</a></p>
                                <h1 className="h2">Changes to this cookie policy</h1>
                                <p>We may update this Website Cookie Policy to reflect changes to our information practices and we will post any changes to this page. Your continued use of our Site following the posting of changes to this Website Cookie Policy will mean that you accept and agree to the changes to the Website Cookie Policy.
                                    We encourage you to periodically review our Website Cookie Policy for the latest information on our privacy practices and to contact us if you have any questions or concerns.</p>
                            </div>
                        </div>
                    </div>
                );
            }
            else {
                return (
                    <div className="container-flex">
                        <div id="homeRoot" className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                            <h1 className="home-page-title">Alerts and Advisories</h1>
                        </div>
                        <div className="card"><div className="card-body"><em>Loading Cookie Policy...</em></div></div>
                    </div>
                );
            }
        }
        else {
            //this error is bubbled up to the ErrorBoundary.js wrapper
            throw Error("An error occured while loading the page. Please contact the site administrator if this error persists.");          
        }
    }

    async getCookiePolicy() {
        this.setState({ loading: false });  
    }
}

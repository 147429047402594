import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardTitle, CardSubtitle, Badge } from 'reactstrap';
import * as moment from 'moment';
import './AlertDetail.css';

export class AlertDetail extends Component {
    //static displayName = AlertDetail.name;

    constructor(props) {
        super(props);
        this.state = { routealertsdetail : [], error: false, loading: true};
    }

    componentDidMount() {
        var transitId = this.props.match.params.transitId;
        var routeShortName = this.props.match.params.routeShortName;
        var alertUniqueId = this.props.match.params.alertUniqueId;

        this.populateRouteAlertsDetailData(transitId, routeShortName, alertUniqueId);   
    }

    static renderRouteAlertsDetailContent(routealertsdetail) {
        function insertSpaces(string) {
            string = string.replace(/([a-z])([A-Z])/g, '$1 $2');
            string = string.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
            return string;
        }

        function formatCardTitle(routeShortName, routeLongName) {
            if (!isNaN(routeShortName) && routeShortName < 0) {
                return "All Routes";
            }
            else {
                return routeShortName + " - " + routeLongName;
            }
        }

        const hasAlerts = routealertsdetail.alerts != null ? routealertsdetail.alerts.length : 0;
        if (hasAlerts > 0) {
            return (
                <div className="mb-4 mt-4">
                    <div className="fw-bold">
                        <h2>Alert Details</h2>
                    </div>
                    <div>
                        <Card>
                            <CardBody>
                                <CardTitle tag="h5">{formatCardTitle(routealertsdetail.routeShortName, routealertsdetail.routeLongName)}</CardTitle>
                                {
                                    routealertsdetail.alerts.map((alert, index) =>
                                        <Card key={index} className="mb-3">                                           
                                            <CardBody>
                                                {/* Debugging info only on DEV*/}
                                                {process.env.REACT_APP_ENV === 'Development' ? alert.gtfsentityId : <span></span>}
                                                <CardTitle><Badge className="alertsBadgeDetail" pill>{insertSpaces(alert.effectText)}</Badge></CardTitle>
                                                <CardSubtitle className="mb-2 text-muted" tag="h6">
                                                    <div>In Effect:</div>
                                                    {!Object.is(alert.alertPeriods, null) && !Object.is(alert.alertPeriods, undefined) ?
                                                        !Object.is(alert.alertPeriods, null) && !Object.is(alert.alertPeriods, undefined) ?
                                                            alert.alertPeriods.map((activePeriod, index3) =>
                                                            (
                                                                Object.is(activePeriod.endDateTime, null) || Object.is(activePeriod.endDateTime, undefined) || Object.is(activePeriod.endDateTime, '1970-01-01T00:00:00') ?
                                                                    <div key={index3}>{moment.utc(activePeriod.startDateTime).local().format('LLL')}</div> :
                                                                    <div key={index3}>{moment.utc(activePeriod.startDateTime).local().format('LLL')} to {moment.utc(activePeriod.endDateTime).local().format('LLL')}</div>
                                                            ))
                                                            : <div></div>
                                                    : <span></span>}
                                                    {!Object.is(alert.alertHeaders, null) && !Object.is(alert.alertHeaders, undefined) ? alert.alertHeaders.map((header, index5) => <div key={index5} dangerouslySetInnerHTML={{ __html: header.text }} />) : <span></span>}
                                                    {!Object.is(alert.alertDescriptions, null) && !Object.is(alert.alertDescriptions, undefined) ? alert.alertDescriptions.map((description, index2) => <div key={index2} dangerouslySetInnerHTML={{ __html: description.text }} />) : <span></span>}
                                                </CardSubtitle>
                                                <div className="card-text">
                                                    <div className="mb-2 text-muted">
                                                        <ul>
                                                            <li>Cause: {insertSpaces(alert.causeText)}</li>
                                                        </ul>
                                                    </div>
                                                    <div>
                                                        {
                                                            !Object.is(alert.alertEntityAffecteds, null) && !Object.is(alert.alertEntityAffecteds, undefined) ?
                                                                <ul className="alertEntityAffectedsUl">
                                                                    {alert.alertEntityAffecteds.map((entityAffected, index4) =>
                                                                    (
                                                                        /*HANDLE CASE FOR CUT-INS */
                                                                        !Object.is(entityAffected.isCutIn, null) && !Object.is(entityAffected.isCutIn, undefined) && !Object.is(entityAffected.isCutIn, '') ?
                                                                            !Object.is(entityAffected.tripId, null) && !Object.is(entityAffected.tripId, undefined) && !Object.is(entityAffected.tripId, '') ?
                                                                                <li key={index4} className="result">
                                                                                    {entityAffected.tripHeadsign} Trip Departure
                                                                                    {
                                                                                        Object.is(entityAffected.departureTime, null) || Object.is(entityAffected.departureTime, undefined) || Object.is(entityAffected.departureTime, '') ? <span></span>
                                                                                            : <span> @{moment(entityAffected.departureTime, 'HH:mm').format('LT')}</span>
                                                                                    }
                                                                                    {
                                                                                        Object.is(entityAffected.stopId, null) || Object.is(entityAffected.stopId, undefined) || Object.is(entityAffected.stopId, '') ? <span></span>
                                                                                            : <span>, Stop {entityAffected.stopCode}, {entityAffected.stopName}{entityAffected.cutInEndDisplay}</span>
                                                                                    }
                                                                                </li>
                                                                                : <span></span>
                                                                            :
                                                                            /*HANDLE NORMAL DATA */
                                                                            Object.is(entityAffected.tripId, null) || Object.is(entityAffected.tripId, undefined) || Object.is(entityAffected.tripId, '') ?
                                                                                <li key={index4} className="result">{Object.is(entityAffected.stopId, null) || Object.is(entityAffected.stopId, undefined) || Object.is(entityAffected.stopId, '') ?
                                                                                    <span className="empty"></span> : <span>Stop {entityAffected.stopCode}, {entityAffected.stopName} {Object.is(entityAffected.departureTime, null) || Object.is(entityAffected.departureTime, undefined) || Object.is(entityAffected.departureTime, '') ? <span></span> : <span> @{moment(entityAffected.departureTime, 'HH:mm').format('LT')}</span>}</span>}
                                                                                </li> :
                                                                                <li key={index4} className="result">
                                                                                    {entityAffected.tripHeadsign} Trip Departure
                                                                                    {Object.is(entityAffected.departureTime, null) || Object.is(entityAffected.departureTime, undefined) || Object.is(entityAffected.departureTime, '') ? <span></span>
                                                                                        : <span> @{moment(entityAffected.departureTime, 'HH:mm').format('LT')}</span>}
                                                                                    {Object.is(entityAffected.stopId, null) || Object.is(entityAffected.stopId, undefined) || Object.is(entityAffected.stopId, '') ?
                                                                                        <span></span>
                                                                                        : <span>, Stop {entityAffected.stopCode}, {entityAffected.stopName} </span>}
                                                                                </li>
                                                                    ))
                                                                    }
                                                                </ul>
                                                                : <span></span>
                                                        }
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    )
                                }
                                <div>
                                    <Link to={{ pathname: '/' }} key={alert.Id}>Back</Link>
                                </div>
                            </CardBody>
                        </Card>                      
                    </div>
                </div>
            )
        }
        else {
            return (
                <div className="mb-4 mt-4">
                    <div className="fw-bold">
                        <h2>Alert Details</h2>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <div>There are no details for this alert.</div>
                            <div>
                                <Link to={{ pathname: '/' }} key={alert.Id}>Back</Link>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    render() {
        let routeAlertsDetailContent = this.state.loading
            ? <div className="mb-4 mt-4">
                <div className="fw-bold">
                    <h2>Alert Details</h2>
                </div><div className="card"><div className="card-body"><em>Loading Alert details...</em></div></div></div>
            : AlertDetail.renderRouteAlertsDetailContent(this.state.routealertsdetail, this.state.open);

        return (
            <div>{routeAlertsDetailContent}</div>
        )
    }

    async populateRouteAlertsDetailData(transitId, routeShortName, alertUniqueId) {
        const response = await fetch('routealertdetail?transitId=' + transitId + '&routeShortName=' + routeShortName + '&alertUniqueId=' + alertUniqueId);
        const data = await response.json();
        if (response.status === 200) {
            this.setState({ routealertsdetail: data, loading: false });
        }
        else {
            this.setState({ error: true, loading: false });
        }
    }
}

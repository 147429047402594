import React, { Component } from 'react';
import { Collapse, Container, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { LoginMenu } from './api-authorization/LoginMenu';
import './NavMenu.css';

export class NavMenu extends Component {
    //static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    render() {
        return (
            <header className="site-header">
                <Navbar className="navbar navbar-expand-sm navbar-toggleable-sm ng-white box-shadow mb-2">
                    <NavbarBrand href="https://www.bctransit.com" title="BC Transit"><img src="/images/logo.svg" className="App-logo" alt="logo" /></NavbarBrand>
                    <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                    <Container>
                        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                           <Nav className="mr-auto" navbar>
                                <NavItem>
                                    <NavLink tag={Link} className="text-light" to="/" title="Home">Home</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} className="text-light" to="/Help" title="Help">Help</NavLink>
                                </NavItem>
                                <LoginMenu></LoginMenu>
                            </Nav> 
                        </Collapse>
                    </Container>
                </Navbar>
            </header>
        );
    }
}

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './PageNotFound.css';

export class PageNotFound extends Component {
    //static displayName = NavMenu.name;

    render() {
        return (        
            <div>
                <h1 className="home-page-title">Error</h1>
                <div className="card">
                    <div className="card-body text-danger">
                        <em>The page you requested could not be found. It might have been moved, had its name changed, or is not available.</em>
                        <p><Link to="/">Return to Alerts</Link></p>
                    </div>
                   
                </div>
            </div>            
        );
    }
}

export default PageNotFound;
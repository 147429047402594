import React, { Component } from 'react';
import { Switch, Route } from "react-router-dom";
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { AlertDetail } from './components/AlertDetail';
import { CookiePolicy } from './components/CookiePolicy';
import { PageNotFound } from './components/PageNotFound'
import { Help } from './components/Help'

import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';

import './custom.css'

export default class App extends Component {
  //static displayName = App.name;

  render () {
    return (
        <Layout>         
            <Switch>
                <Route exact path='/' component={Home} />
                <Route path='/RouteAlerts/*' component={Home} />
                <Route exact path='/RouteAlertsDetail/:transitId/:routeShortName/:alertUniqueId' component={AlertDetail} />
                <Route exact path='/CookiePolicy' component={CookiePolicy} />
                <Route exact path='/Help' component={Help} />
                <Route exact path='/PageNotFound' component={PageNotFound} />
                <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
                <Route exact path='/:transitId' component={Home} />
                <Route path='*' component={PageNotFound} />               
            </Switch>             
        </Layout> 
    );
  }
}

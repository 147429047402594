import React, { Component } from 'react';
import './Footer.css';

export class Footer extends Component {
    //static displayName = Footer.name;

  render() {
    return (
        <footer className="site-footer">
            <div className="container-fluid site-copyright">
                <div className="page-width">                 
                    <div className="d-flex align-items-center">
                        <div className="p-2">
                            <h3 className="site-title"><a href="https://www.bctransit.com" title="BC Transit"><img src="/images/logo.svg" className="App-logo" alt="logo" /></a></h3>    
                        </div>
                        <div className="p-2" id="footerCopyRightDiv">
                            <span>BC Transit Corporation. All Rights Reserved.</span>
                        </div>
                        <div className="ml-auto p-2">
                            <a href="#top">Back to top</a>
                        </div>
                    </div>             
                </div>
            </div>
      </footer>
    );
  }
}

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Layout } from './Layout';
class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, errorMessage: '' };
        this.resetErrorBoundary = this.resetErrorBoundary.bind(this);
    }
   
    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return {
            hasError: true,
            errorMessage : error.toString(),
        };
    }

    componentDidCatch(error, errorInfo) {
        this.setState({ hasError: true });
        // You can also log the error to an error reporting service
        //alert(error);
    }

    resetErrorBoundary() {
        this.setState({ hasError: false });
    }

    render() {
        if (this.state.hasError) {
            return (
                <Layout>
                    <div id="homeRoot" className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                        <h1 className="home-page-title">Error</h1>
                    </div>
                    <div className="card">
                        <div className="card-body text-danger">
                            <em>An Error occured: {this.state.errorMessage}</em>
                            <p><Link onClick={this.resetErrorBoundary} to="/">Return to Alerts</Link></p>
                        </div>
                    </div>
                </Layout>
            );         
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
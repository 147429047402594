import React, { Component } from 'react';
import './Help.css';

export class Help extends Component {
    //static displayName = CookiePolicy.name;

    constructor(props) {
        super(props);
        this.state = { loading: true, error: false };
    }

    componentDidMount() {
        this.getHelp();
    }

    render() {
        if (!this.state.error) {
            if (!this.state.loading) {
                return (
                    <div className="container-flex">
                        <div id="homeRoot" className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                            <h1 className="home-page-title">Alerts and Advisories</h1>
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <h1 className="h2">Help</h1>
                                <div>
                                    Please read the FAQs below for tips on performing common functions within BC Transit Alerts. If you still need assistance, feel free to reach out to our friendly Customer Relations Agents by email at <a href="mailto:transitinfo@bctransit.com" title="transitinfo@bctransit.com">transitinfo@bctransit.com</a>, or by phone at 250-382-6161.
                                </div><br />
                                <div>
                                    <h3>FAQs</h3>
                                </div>
                                <div className="accordion" id="accordionFAQ">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingOne">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                What are BC Transit Alerts?
                                            </button>
                                        </h2>
                                        <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionFAQ">
                                            <div className="accordion-body">
                                                <p>Sign-up to receive alert notifications for your frequent stops and routes. By doing so, you will receive an automatic notification via email and/or text message, of detours, stop closures or service interruptions affecting your preferred routes.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingTwo">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                How do I register?
                                            </button>
                                        </h2>
                                        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionFAQ">
                                            <div className="accordion-body">
                                                <ol type="1">
                                                    <li>Click 'Login' from the top navigation links</li>
                                                    <li>Click 'Register Now' from the page</li>
                                                    <li>Fill out the registration form</li>
                                                    <li>Click the 'Register' button</li>
                                                    <li>A confirmation email will be sent to the email address you provided</li>
                                                    <li>You must confirm registration by following the instructions provided in the email</li>
                                                </ol>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingThree">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                How do I Login?
                                            </button>
                                        </h2>
                                        <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionFAQ">
                                            <div className="accordion-body">
                                                <p>Once you have confirmed your registration you can log into your account.</p>
                                                <ol type="1">
                                                    <li>Click 'Login' from the top navigation links</li>
                                                    <li>Fill out the login form</li>
                                                    <li>Click the 'Login' button</li>
                                                    <li>Once logged in, a 'My Account' link will be visible in the top navigation</li>
                                                </ol>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingFour">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                How do I subscribe to receive alerts?
                                            </button>
                                        </h2>
                                        <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionFAQ">
                                            <div className="accordion-body">
                                                <p>Once you have an account, and are logged in, you can subscribe to receive notifications of BC Transit alerts and advisories.</p>
                                                <ol type="1">
                                                    <li>Login to your account</li>
                                                    <li>Click 'My Account' from the top navigation links</li>
                                                    <li>Click 'Subscriptions' from the left-hand navigation links</li>                                             
                                                    <li>Click the 'Create Alert Subscription' button</li>
                                                    <li>Select the Transit System you are interested in</li>
                                                    <li>Select the Route that you are interested in for the Transit System</li>
                                                    <li>Click the 'Create' button</li>
                                                    <li>Click 'Delivery Settings' from the left-hand navigation links</li>
                                                    <li>Select your desired method of delivery (Email or Text Message). Please note that Text Message delivery is only enabled for certain Transit Systems</li>
                                                    <li>Click the 'Save' button</li>
                                                </ol>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingFive">
                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                How do I unsubscribe from receiving alerts?
                                            </button>
                                        </h2>
                                        <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionFAQ">
                                            <div className="accordion-body">
                                                <p>There are multiple ways to unsubscribe from receiving alert notifcations:</p>
                                                <p>Through the website:</p>
                                                <ol type="1">
                                                    <li>Login to your account</li>
                                                    <li>Click 'My Account' from the top navigation links</li>
                                                    <li>Click 'Subscriptions' from the left-hand navigation links</li>
                                                    <li>Click the 'Delete' button for the Transit System and Route that you have subscribed to</li>
                                                </ol>
                                                <p>Or,</p>
                                                <ol type="1">
                                                    <li>Login to your account</li>
                                                    <li>Click 'Delivery Settings' from the left-hand navigation links</li>
                                                    <li>Un-check the selected Delivery method</li>
                                                    <li>Click the 'Delete' button for the Transit System and Route that you have subscribed to</li>
                                                </ol>
                                                <p>Through an email notification sent to you:</p>
                                                <ol type="1">
                                                    <li>Click the hyperlink link at the bottom of the message that says 'click here' if you would like to unsubscribe</li>
                                                </ol>
                                                <p>From an SMS text message notification sent to you:</p>
                                                <ol type="1">
                                                    <li>Reply to the message with the keyword 'STOP'</li>
                                                </ol>
                                            </div>
                                        </div>
                                    </div>                              
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
            else {
                return (
                    <div className="container-flex">
                        <div id="homeRoot" className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                            <h1 className="home-page-title">Alerts and Advisories</h1>
                        </div>
                        <div className="card"><div className="card-body"><em>Loading Cookie Policy...</em></div></div>
                    </div>
                );
            }
        }
        else {
            //this error is bubbled up to the ErrorBoundary.js wrapper
            throw Error("An error occured while loading the page. Please contact the site administrator if this error persists.");
        }
    }

    async getHelp() {
        this.setState({ loading: false });
    }
}

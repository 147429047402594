import React, { Component } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Card, CardBody, Badge, Button } from 'reactstrap';
import Cookies from 'js-cookie';
import './TransitSystem.css';

export class TransitSystem extends Component {
    //static displayName = TransitSystem.name;

    constructor(props) {
        super(props);
        this._isMounted = false;

        this.state = { transitsystems: [], selectedSystem: null, loading: true, showOffCanvas: false, error: false, errorMessage: '' };
        
        // Binding this keyword  
        this.updateState = this.updateState.bind(this);
        this.handleShowOffCanvas = this.handleShowOffCanvas.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this._isMounted && this.populateTransitSystemData();
        this._isMounted && this.getSelectedSystem();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getSelectedSystem() {
        const name = 'BCTransitCurrentSystem';
        const consentName = 'BCTransitCookieConsent';
        const consentCookie = Cookies.get(consentName);

        //failsafe. If consent cookie doesn't exist, clear out other cookie
        if (consentCookie === null || consentCookie === undefined) {
            const cookie = Cookies.get(name);
            if (cookie !== null && cookie !== undefined) {
                //if it exists, delete it, and recreate
                Cookies.remove(name);
            }
        }

        if (consentCookie !== null && consentCookie !== undefined) {
            if (consentCookie === 'true') {
                const cookie = Cookies.get(name);
                if (cookie !== null && cookie !== undefined) {
                    this.setState({ selectedSystem: cookie });
                }
                else {
                    this.setState({ selectedSystem: '' });
                }
            }
            else {
                this.setState({ selectedSystem: '' });
            }
        }
        else {
            this.setState({ selectedSystem: '' });
        }
    }

    setCookieValue(value) {
        const name = 'BCTransitCookieConsent';
        const consentCookie = Cookies.get(name);
        if (consentCookie !== null && consentCookie !== undefined) {
            if (consentCookie === 'true') {
                const name = 'BCTransitCurrentSystem';
                const cookie = Cookies.get(name);
                if (cookie !== null && cookie !== undefined) {
                    //if it exists, delete it, and recreate
                    Cookies.remove(name);
                }
                //if it doesn't exist, create it
                Cookies.set(name, value, { expires: 30 });
            }
        }
    }

    updateState(transitSystemId) {
        this.props.handleCallBack(transitSystemId);

        // Changing state  
        this.setState({ selectedSystem: transitSystemId })

        this.setCookieValue(transitSystemId);
    }

    handleShowOffCanvas(e) {
        this.setState({ showOffCanvas: true });
        e.currentTarget.blur(); //remove focus from button
    }

    render() {
         if (!this.state.error) {
            //const handleShowOffCanvas = () => this.setState({ showOffCanvas: true });        
            const handleCloseOffCanvas = () => this.setState({ showOffCanvas: false });
            const selectedSystem = this.state.selectedSystem === null ? "" : this.state.selectedSystem.toString();

            if (this.state.loading) {
                return (<div className="d-grid gap-2 currentTransitSystemWrapper"><Button id="currentTransitSystem" className="btn btn-primary" size="lg" disabled><i>Loading Transit Systems...</i></Button></div>);
            }
            if (this.state.transitsystems.length > 0) {
                return (
                    <div className="d-grid gap-2 currentTransitSystemWrapper">
                        <Button id="currentTransitSystem" onClick={this.handleShowOffCanvas} className="btn btn-primary" size="lg" title="Click to select a Transit System">
                            <label>{selectedSystem === '' || selectedSystem === null || selectedSystem === 'undefined' || selectedSystem === undefined ? '-- Click here to select a Transit System --' : this.state.transitsystems.find(system => system.id.toString() === selectedSystem) !== undefined ? this.state.transitsystems.find(system => system.id.toString() === selectedSystem).title : '-- Click here to select a Transit System --'}</label>
                        </Button>
                        <Offcanvas placement="top" show={this.state.showOffCanvas} onHide={handleCloseOffCanvas}>
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title>Select Transit System</Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body className="offcanvas-body">
                                <ol className="multicolumn" key="olTransitSystems">
                                    {this.state.transitsystems.map((item) => (
                                        <li key={item.id} onClick={() => this.updateState(item.id)} value={item.id} title={item.title}>{item.title}</li>
                                    ))}
                                </ol>
                            </Offcanvas.Body>
                        </Offcanvas>
                    </div>
                );
            }
            else {
                return (
                    <div className="d-grid gap-2">
                        <Card>
                            <CardBody>
                                <Badge className="alertsBadge">There are no Active Transit Systems.</Badge>
                            </CardBody>
                        </Card>
                    </div>
                );
            }
        }
        else {
            //this error is bubbled up to the ErrorBoundary.js wrapper
             throw Error("The Transit System you requested could not be found. It might have been moved, had its name changed, or is not available.");
        }
    }

    async populateTransitSystemData() {
        var transitSystemNotFoundError = "The Transit System you requested could not be found. It might have been moved, had its name changed, or is not available.";

        //if the user came here via specific URL query for a transitID, override the cookie value
        if ((window.location.pathname.includes("/") && window.location.pathname.lastIndexOf("/") === 0) || window.location.pathname.toLowerCase().includes("routealerts")) {
            //transitId is the last value in query
            var value = window.location.pathname.split("/").pop();
            if (isNaN(value)) {
                this._isMounted && this.updateState('');
            }
        }

        const response = await fetch('transitsystem');
        if (response.status === 200) {
            const data = await response.json();
            var selectedSystemId = '';
            var system = '';
            //if the user came here via specific URL query for a transitID, override the cookie value
            if ((window.location.pathname.includes("/") && window.location.pathname.lastIndexOf("/") === 0) || window.location.pathname.endsWith("/") || window.location.pathname.toLowerCase().includes("routealerts")) {
                //transitId is the last value in query
                if (window.location.pathname.endsWith("/")) {
                    //handle case for trailing slash
                    value = window.location.pathname.substring(0, window.location.pathname.length - 1).split("/").pop();
                }
                else {
                    value = window.location.pathname.split("/").pop();
                }
                
                if (isNaN(value)) {
                    //find system based on title
                    system = data.find(system => system.titleUrl.toString().toLowerCase() === value.toString().toLowerCase());
                    if (system !== undefined) {
                        selectedSystemId = system.id;
                        this._isMounted && this.updateState(selectedSystemId);
                    }
                    else {
                        //this error is bubbled up to the ErrorBoundary.js wrapper
                        this._isMounted && this.setState({ error: true, errorMessage: transitSystemNotFoundError });
                        //window.location.href = "/PageNotFound";
                    }
                }
                else {
                    if (value !== '') {
                        //find system based on id
                        system = data.find(system => system.id.toString() === value.toString());
                        if (system !== undefined) {
                            selectedSystemId = system.id;
                            this._isMounted && this.updateState(selectedSystemId);
                        }
                        else {
                            //this error is bubbled up to the ErrorBoundary.js wrapper
                            this._isMounted && this.setState({ error: true, errorMessage: transitSystemNotFoundError });
                            //window.location.href = "/PageNotFound";
                        }
                    }
                }
            }

            this._isMounted && this.setState({ transitsystems: data, selectedOption: selectedSystemId, loading: false });
        } else {
            this._isMounted && this.setState({ error: true });
        }
    }
}

import React, { Component } from 'react';
import { Alert } from './Alert';
import './Home.css';

export class Home extends Component {
    //static displayName = Home.name;

    constructor(props) {
        super(props);
        this.state = { activeAlertTypes: [], loading: true, error: false };
    }

    componentDidMount() {
        this.getActiveAlertTypes();       
    }

    render() {
        if (!this.state.error) {
            if (!this.state.loading) {
                return (
                    <div>
                        <Alert activeAlertTypes={this.state.activeAlertTypes}></Alert>
                    </div>
                );
            }
            else {
                return (
                    <div className="container-flex">
                        <div id="homeRoot" className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                            <h1 className="home-page-title">Alerts and Advisories</h1>
                        </div>
                        <div className="card"><div className="card-body"><em>Loading Alerts and Advisories...</em></div></div>
                    </div>
                );
            }
        }
        else {
            //this error is bubbled up to the ErrorBoundary.js wrapper
            throw Error("An error occured while processing the the Real Time feeds. Please contact the site administrator if this error persists.");
            /*return (
                <div className="container-flex">
                    <div id="homeRoot" className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                        <h1 className="home-page-title">Alerts and Advisories</h1>
                    </div>
                    <Card>
                        <CardBody>
                            <Badge className="alertsBadge">An error occured while processing the the Real Time feeds. Please contact the site administrator if this error persists.</Badge>
                        </CardBody>
                    </Card>                  
                </div>
            );*/
        }
    }

    async getActiveAlertTypes() {
        this.setState({ activeAlertTypes: [] });
        const response = await fetch('home');
        if (response.status !== 200) {
            this.setState({ error: true });
        }
        else { 
            const data = await response.json();
            this.setState({ activeAlertTypes: data, loading: false });       
        }
    }
}
